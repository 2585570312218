import { NavLink as RemixNavLink } from "@remix-run/react";
import { getNavlinks } from "../constants/navLinks";
import { getCmsRole } from "~/utils/utils";
import { getCMSEnvironment } from "~/server/utils";

const MiniSidebar = () => {
  const cmsRole = getCmsRole() ?? undefined;
  const isProduction = getCMSEnvironment() === "production";

  const classes = `fixed bottom-0 left-0 top-0 z-50 overflow-y-scroll p-4 no-scrollbar ${isProduction ? "bg-menuBgColor" : "bg-[#19334d]"}`;

  return (
    <section className={classes}>
      <div className="grid place-items-center h-full gap-2">
        {getNavlinks(cmsRole).map((group, idx) => (
          <div key={idx} className="flex flex-col gap-2 font-Alianza600 pb-3 ">
            {group.links.map((link, i) => (
              <div
                key={i}
                className="flex flex-col cursor-pointer gap-4 text-menuTextColor"
              >
                <NavLink link={link.path}>{link.icon}</NavLink>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};

export default MiniSidebar;

type Props = {
  link: string;
  children: React.ReactNode;
  getColor?: boolean;
};

const NavLink = ({ link, children, getColor }: Props) => {
  const getActiveColor = ({ isActive }: { isActive: boolean }) => ({
    color: isActive ? "var(--clr-primary)" : "hsla(47, 21%, 86%, 0.8)",
  });

  return (
    <RemixNavLink
      key={link}
      to={link}
      className="hover:text-primary-100 flex items-center"
      style={getColor ? getActiveColor : undefined}
    >
      {children}
    </RemixNavLink>
  );
};
